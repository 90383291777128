/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MARKET: `mercado`,
    TRADE: `troca`,
    SPOT: `Negociação à vista`,
    OPTIONS: `negociação de opções`,
    CONTRACT: `negociação de contrato`,
    ASSET: `ativos`,
    COPY_TRADE: `Seguir ordens`,
    LOAN: `empréstimo`,
    USER_CENTER: `Centro Pessoal`,
    SIGNIN: `Conecte-se`,
    SIGNUP: `registro`,
    SLOGAN: `ETD COINS`
};
